.header{
    width: 100%;
    min-height: 70px;
    position: sticky;
    top:0;
    left: 0;
    z-index: 2;
    box-shadow: 0 0 10px #ddd;
    background: #f8f9fa;
    border: 1px solid rgb(0, 0, 0, .125);
}
.header .navbar{
    width: 100%;
    height: 100%;
}
.header .logo h1{
    font-size: 2rem;
} 

/* ----------- Header Menu ---------- */
.header .menu li{
    opacity: 0.5;
}
.header .menu li.active{
    opacity: 1;
}
.header .menu .material-icons{
    font-size: 30px;
}
.header .menu .dropdown-menu{
    position: absolute;
    left: inherit;
    right: 0;
}
.header .menu label{
    cursor: pointer;
}

.header .menu .notify_length{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    font-size: 10px;
}

/* ---------- Header Search -------- */
.header .search_form{
    position: relative;
}
.header .search_form #search{
    background: #fafafa;
    border: 1px solid #ddd;
    min-width: 250px;
    width: 100%;
    outline: none;
    text-indent: 5px;
    border-radius: 3px;
}
.header .search_form .search_icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    pointer-events: none;
}
.header .search_form .search_icon .material-icons{
    font-size: 14px;
    transform: translateY(3px);
}
.header .search_form .close_search{
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    font-weight: 900;
    color: crimson;
}
.header .search_form .users{
    position: absolute;
    width: 100%;
    min-width: 250px;
    background: #fafafa;
    max-height: calc(100vh - 150px);
    overflow: auto;
    margin-top: 3px;
}
.header .search_form .loading{
    position: absolute;
    top: 50%;
    right: 5px;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
}

