.auth_page{
    width: 100%;
    min-height: 100vh;
    background: #fdfdfd;
    display: flex;
    align-items: center;
    justify-content: center;
}
.auth_page form{
    background: #fff;
    max-width: 400px;
    width: 100%;
    border: 1px solid #ddd;
    padding: 50px 25px;
}

.auth_page form .pass{
    position: relative;
}

.auth_page form .pass small{
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.5;
}